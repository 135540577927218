/* eslint-disable n/no-process-env */

export default Object.freeze({
  NODE_ENV: process.env.NODE_ENV,

  API_HOST: 'https://azapi.aivatar.ai',
  WEBSOCKET_HOST: 'wss://azapi.aivatar.ai',

  // 결제 관련 환경변수 .
  IMP: 'imp85016062',
  PG: 'tosspayments.bill_im_ein65s',
  PAY_NAME: '최초인증결제',
})
